import * as React from "react"
import Page from "../components/Page";
import Layout from "../components/Layout";

const Index = () => {
    return <Layout>
        <h1 style={{

            maxWidth: 600,
            padding: '10vw',

        }}>404 | Seite wurde nicht gefunden.</h1>
    </Layout>
}
export default Index;
